import React from 'react';
// sourced from https://www.svgrepo.com/svg/74695/magnifying-glass
const MagnifyingGlassIcon = () => (
  <svg
    fill="#ff8112"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 203.786 203.786"
    style={{ width: '100%', height: '100%' }} // Make the SVG responsive
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round">
    </g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          d={`
          M107.387,49.855
          c9.34,9.292,13.223,21.961,11.71,34.09
          c-1.1-8.73-4.972-17.191-11.71-23.874
          c-16.015-15.93-41.91-15.844-57.833,0.166
          C42.913,66.919,39.1,75.307,38.016,83.975
          c-1.513-12.052,2.302-24.659,11.538-33.942
          C65.477,34.011,91.372,33.932,107.387,49.855
          z
          M203.786,186.313
          l-17.064,17.171
          l-50.603-50.307
          l4.268-4.291
          l-10.609-10.557
          c-14.251,12.318-32.19,19.086-51.226,19.086
          c-20.9,0-40.566-8.121-55.387-22.857
          C8.287,119.771,0.06,100.07,0,79.084
          c-0.062-20.978,8.056-40.729,22.848-55.612
          c14.859-14.948,34.64-23.17,55.709-23.17
          c20.895,0,40.568,8.119,55.387,22.852
          c14.878,14.797,23.1,34.498,23.158,55.478
          c0.06,18.95-6.614,36.854-18.79,51.117
          l10.604,10.539
          l4.273-4.292
          L203.786,186.313
          z
          M125.675,125.717
          c12.508-12.59,19.382-29.291,19.335-47.038
          c-0.054-17.747-7.011-34.415-19.589-46.932
          c-12.542-12.469-29.185-19.328-46.857-19.328
          c-17.818,0-34.555,6.957-47.126,19.594
          C18.921,44.597,12.059,61.301,12.112,79.054
          c0.047,17.747,7.01,34.416,19.591,46.922
          c12.537,12.472,29.175,19.334,46.854,19.334
          C96.364,145.316,113.109,138.354,125.675,125.717
          z
        `}/>
      </g>
    </g>
  </svg>
);

export default MagnifyingGlassIcon;
